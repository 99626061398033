export const home_header_es = [
    {
        title: "Digitalizar tus ideas es nuestro reto",
        intro: "Somos una consultora de software & marketing que hace realidad cualquier idea que tengas para una aplicación, website, diseño o campaña. Creamos " +
            "experiencias tecnológicas de fácil usabilidad y excelente accesibilidad para poder generar impacto sobre tus usuarios. Optimizamos " +
            "tu huella digital para que adquieras mejor posicionamiento y obtengas mayor ratio de conversión de leads."
    }

]
export const home_header_en = [
    {
        title: "Our challenge is to digitize your ideas",
        intro: "We are a software & marketing consultancy company which brings any app, website, design or campaign idea to life. We create technological experiences " +
            "that are easy to use and have great accessibility to create impact on users. We also optimize your digital footprint to increase " +
            "brand recognition to achieve greater lead conversion rate.",
    }
]


export const home_info_es = [
    {
        title: "Nuestros servicios",
        blogTitle: "BLOG"
    }
]

export const home_info_en = [
    {
        title: "Our services",
        blogTitle: "BLOG"
    }
]

export const client_data = [
    { image: "Ideen B_McCann.png" },
    { image: "Ideen_B_amazon.png" },
    { image: "Ideen_B_Calidra.png" },
    { image: "Ideen_B_Coppel.png" },
    { image: "Ideen_B_EatPass.png" },
    { image: "Ideen_B_Ital.png" },
    { image: "Ideen_B_Kontakt.png" },
    { image: "Ideen_B_Kuida.png" },
    { image: "Ideen_B_Lacandonia.png" },
    { image: "Ideen_B_Lazzos.png" },
    { image: "Ideen_B_Mezcla Brava.png" },
    { image: "Ideen_B_Oriflame.png" },
    { image: "Ideen B_McCann.png" },
    { image: "Ideen_B_meat me.png" },
    { image: "Ideen_B_Patriot.png" },
    { image: "Ideen_B_Puntli.png" },
    { image: "Ideen_B_Rational.png" },
    { image: "Ideen_B_Renault.png" },
    { image: "Ideen_B_Samsung.png" },
    { image: "Ideen_B_TecMty.png" },
    { image: "Ideen_B_Tracy.png" },
    { image: "Ideen_B_Vanessa Bauche.png" },
]

export const home_btn = [
    {
        w: "250px",
        w2: "200px",
        w3: "180px",
        button1: "APPS MÓVILES",
        color1: "#09b408",
        button2: "WEB APPS",
        color2: "#fe7700",
        button3: "WEBSITES",
        color3: "#ffbb00",
        button4: "E-COMMERCE",
        color4: "#16cccc",
        button5: "MARKETING DIGITAL ORGÁNICO",
        color5: "#fd007f",
        button6: "MARKETING DIGITAL PAUTADO",
        color6: "#9961f2",
        button7: "BRANDING & DISEÑO",
        color7: "#3f63d9",
        button8: "VER MÁS NOTAS",
        color8: "#3f63d9",

    }
]

export const home_btn_en = [
    {
        w: "250px",
        w2: "200px",
        w3: "180px",
        button1: "MOBILE APPS",
        color1: "#09b408",
        button2: "WEB APPS",
        color2: "#fe7700",
        button3: "WEBSITES",
        color3: "#ffbb00",
        button4: "E-COMMERCE",
        color4: "#16cccc",
        button5: "ORGANIC MARKETING",
        color5: "#fd007f",
        button6: "PAID MARKETING",
        color6: "#9961f2",
        button7: "BRANDING & DESIGN",
        color7: "#3f63d9",
        button8: "SEE MORE ENTRIES",
        color8: "#3f63d9",

    }
]