<template>
    <v-row class="content fill-height" no-gutters>
        <v-col cols="12">
            <!-- JUMBOTRON -->
            <v-img
                :src="require(`@/assets/home/ideen_Jumbotron.png`)"
                class="jumbotron"
            ></v-img>

            <!-- SLOGAN -->
            <v-container mt-16 mb-4>
                <v-row align="center" justify="center">
                    <p class="text-center px-2 customTitle" id="slogan">
                        {{ headerText[0]['title'] }}</p>
                </v-row>
            </v-container>

            <!-- INTRO -->
            <v-container mt-0 mb-2>
                <v-row align="center" justify="center" id="mainText">
                    <p class="text-center homeScreenText">
                        {{ headerText[0]['intro'] }}</p>
                </v-row>
            </v-container>
        </v-col>

        <!-- SERVICES TITLE -->
        <v-container class="mt-lg-8 mt-8 mb-lg-0 mb-n6">
            <v-row align="center" justify="center">
                <p class="text-center subtitleText customTitle">
                    {{ bodyText[0]['title'] }}</p>
            </v-row>
        </v-container>

        <!-- XL BREAKPOINT SERVICES BUTTONS -->
        <v-container v-if="windowWidth >= 1904 || windowWidth <= 420">
            <v-col align="center">
                <v-row class="" align="center" justify="center" fluid>
                    <!-- MOBILE APPS BUTTON -->
                    <v-col lg="2" md="3" sm="3" class="flex-grow-0 flex-shrink-1" align="center">
                        <Button
                            :name="btnText[0]['button1']"
                            :color="btnText[0]['color1']"
                            :w="btnText[0]['w']"
                            :index="0"
                            usage="services"
                            :usage-function="gotoServiceScreen"
                        ></Button>
                    </v-col>

                    <!-- WEB APPS BUTTON -->
                    <v-col lg="2" md="3" sm="3" class="flex-grow-0 flex-shrink-1">
                        <Button
                            :name="btnText[0]['button2']"
                            :color="btnText[0]['color2']"
                            :w="btnText[0]['w']"
                            :index="1"
                            usage="services"
                            :usage-function="gotoServiceScreen"
                        ></Button>
                    </v-col>

                    <!-- WEBSITES BUTTON -->
                    <v-col lg="2" md="3" sm="3" class="flex-grow-0 flex-shrink-1">
                        <Button
                            :name="btnText[0]['button3']"
                            :color="btnText[0]['color3']"
                            :w="btnText[0]['w']"
                            :index="2"
                            usage="services"
                            :usage-function="gotoServiceScreen"
                        ></Button>
                    </v-col>

                    <!-- E-COMMERCE BUTTON -->
                    <v-col lg="2" md="3" sm="3" class="flex-grow-0 flex-shrink-1">
                        <Button
                            :name="btnText[0]['button4']"
                            :color="btnText[0]['color4']"
                            :w="btnText[0]['w']"
                            :index="3"
                            usage="services"
                            :usage-function="gotoServiceScreen"
                        ></Button>
                    </v-col>

                </v-row>
                <v-row class="" align="center" justify="center">
                    <!-- ORGANIC MARKETING BUTTON -->
                    <v-col lg="2" md="3" sm="3" class="flex-grow-0 flex-shrink-1">
                        <Button
                            :name="btnText[0]['button5']"
                            :color="btnText[0]['color5']"
                            :w="btnText[0]['w']"
                            :index="4"
                            usage="services"
                            :usage-function="gotoServiceScreen"
                        ></Button>
                    </v-col>

                    <!-- PAID MARKETING BUTTON -->
                    <v-col lg="2" md="3" sm="3" class="flex-grow-0 flex-shrink-1">
                        <Button
                            :name="btnText[0]['button6']"
                            :color="btnText[0]['color6']"
                            :w="btnText[0]['w']"
                            :index="5"
                            usage="services"
                            :usage-function="gotoServiceScreen"
                        ></Button>
                    </v-col>

                    <!-- BRANDING & DESIGN BUTTON -->
                    <v-col lg="2" md="3" sm="3" class="flex-grow-0 flex-shrink-1">
                        <Button
                            :name="btnText[0]['button7']"
                            :color="btnText[0]['color7']"
                            :w="btnText[0]['w']"
                            :index="6"
                            usage="services"
                            :usage-function="gotoServiceScreen"
                        ></Button>
                    </v-col>
                </v-row>
            </v-col>
        </v-container>

        <!-- SERVICES BUTTONS -->
        <v-container mt-4 mb-4 v-else>
            <v-col align="center">
                <v-row class="" align="center" justify="center" fluid>
                    <!-- MOBILE APPS BUTTON -->
                    <v-col lg="2" md="3" sm="3" class="flex-grow-0 flex-shrink-1" align="center">
                        <Button
                            :name="btnText[0]['button1']"
                            :color="btnText[0]['color1']"
                            :w="btnText[0]['w3']"
                            :index="0"
                            usage="services"
                            :usage-function="gotoServiceScreen"
                        ></Button>
                    </v-col>

                    <!-- WEB APPS BUTTON -->
                    <v-col lg="2" md="3" sm="3" class="flex-grow-0 flex-shrink-1">
                        <Button
                            :name="btnText[0]['button2']"
                            :color="btnText[0]['color2']"
                            :w="btnText[0]['w3']"
                            :index="1"
                            usage="services"
                            :usage-function="gotoServiceScreen"
                        ></Button>
                    </v-col>

                    <!-- WEBSITES BUTTON -->
                    <v-col lg="2" md="3" sm="3" class="flex-grow-0 flex-shrink-1">
                        <Button
                            :name="btnText[0]['button3']"
                            :color="btnText[0]['color3']"
                            :w="btnText[0]['w3']"
                            :index="2"
                            usage="services"
                            :usage-function="gotoServiceScreen"
                        ></Button>
                    </v-col>

                    <!-- E-COMMERCE BUTTON -->
                    <v-col lg="2" md="3" sm="3" class="flex-grow-0 flex-shrink-1">
                        <Button
                            :name="btnText[0]['button4']"
                            :color="btnText[0]['color4']"
                            :w="btnText[0]['w3']"
                            :index="3"
                            usage="services"
                            :usage-function="gotoServiceScreen"
                        ></Button>
                    </v-col>
                </v-row>
                <v-row class="" align="center" justify="center">
                    <!-- ORGANIC MARKETING BUTTON -->
                    <v-col lg="2" md="3" sm="3" class="flex-grow-0 flex-shrink-1">
                        <Button
                            :name="btnText[0]['button5']"
                            :color="btnText[0]['color5']"
                            :w="btnText[0]['w3']"
                            :index="4"
                            usage="services"
                            :usage-function="gotoServiceScreen"
                        ></Button>
                    </v-col>

                    <!-- PAID MARKETING BUTTON -->
                    <v-col lg="2" md="3" sm="3" class="flex-grow-0 flex-shrink-1">
                        <Button
                            :name="btnText[0]['button6']"
                            :color="btnText[0]['color6']"
                            :w="btnText[0]['w3']"
                            :index="5"
                            usage="services"
                            :usage-function="gotoServiceScreen"
                        ></Button>
                    </v-col>

                    <!-- BRANDING & DESIGN BUTTON -->
                    <v-col lg="2" md="3" sm="3" class="flex-grow-0 flex-shrink-1">
                        <Button
                            :name="btnText[0]['button7']"
                            :color="btnText[0]['color7']"
                            :w="btnText[0]['w3']"
                            :index="6"
                            usage="services"
                            :usage-function="gotoServiceScreen"
                        ></Button>
                    </v-col>
                </v-row>
            </v-col>
        </v-container>

        <!-- SLIDER CUSTOMERS LIST -->
        <v-container mt-16 mb-11 fluid class="py-0 px-0">
            <v-card color="havelockBlue" class="fill-width" min-width="100%" elevation="0">
                <HomeClients_ScrollBar></HomeClients_ScrollBar>
            </v-card>
        </v-container>

        <!-- BLOG SECTION -->
        <!-- Loading indicator -->
        <v-container class="pt-16" v-if="blogList.length === 0 || imgLinkList.length > 1" fluid>
            <div class="content fill-height">
                <v-container class="fill-height" fluid>
                    <!-- BLOG TITLE -->
                    <v-row justify="start" align="start">
                        <h1 id="blogTitle" class="customTitle">{{ bodyText[0]['blogTitle'] }}</h1>
                    </v-row>

                    <!-- CIRCULAR PROGRESS INDICATOR -->
                    <v-row justify="center" align="center">
                        <v-progress-circular
                            indeterminate
                            color="ideenBlue"
                            :size="90"
                            :width="9"
                        ></v-progress-circular>

                    </v-row>
                </v-container>
            </div>
        </v-container>

        <!-- Actual Blog Section -->
        <v-container fluid v-else>
            <v-col align="center">
                <!-- BLOG TITLE -->
                <v-row align="center" align-content="center" justify="start">
                    <p id="blogTitle" class="customTitle">{{ bodyText[0]['blogTitle'] }}</p>
                </v-row>

                <!-- BLOG ENTRIES CAROUSEL -->
                <v-row v-if="imgLinkList.length > 0 && blogList.length > 0" align="center" justify="center" class="px-lg-8 pt-lg-n8 mt-lg-n16">
                    <v-col cols="12" lg="4" align="center">
                        <BlogEntry_Card
                            color="white"
                            :title="blogList[0]['title']"
                            :category="blogList[0]['category']"
                            :date="blogList[0]['publishDate'].toDate().toDateString()"
                            :img="imgLinkList[0][0]"
                            :select="(0).toString()"
                            :id="blogList[0]['id']"
                        ></BlogEntry_Card>
                    </v-col>

                    <v-col cols="12" lg="4" align="center">
                        <!--Deja un espacio para bajar la tarjeta central en caso de haber 3 por cada slide-->
                        <h1
                            class="hidden-sm-and-down"
                            style="color: #ffffff;"
                        >&nbsp;</h1>

                        <h1
                            class="hidden-sm-and-down"
                            style="color: #ffffff;"
                        >&nbsp;</h1>

                        <BlogEntry_Card
                            color="white"
                            :title="blogList[1]['title']"
                            :category="blogList[1]['category']"
                            :date="blogList[1]['publishDate'].toDate().toDateString()"
                            :img="imgLinkList[0][1]"
                            :select="(1).toString()"
                            :id="blogList[1]['id']"
                        ></BlogEntry_Card>
                    </v-col>

                    <v-col cols="12" lg="4" align="center">
                        <BlogEntry_Card
                            color="white"
                            :title="blogList[2]['title']"
                            :category="blogList[2]['category']"
                            :date="blogList[2]['publishDate'].toDate().toDateString()"
                            :img="imgLinkList[0][2]"
                            :select="(2).toString()"
                            :id="blogList[2]['id']"
                        ></BlogEntry_Card>
                    </v-col>

                </v-row>
            </v-col>
        </v-container>

        <!-- CTA BUTTON -->
        <v-container class="mt-8 mb-16 pb-16">
            <v-col align="center">
                <v-row class="mx-16" align="center" justify="center">
                    <Button
                        :name="btnText[0]['button8']"
                        :color="btnText[0]['color8']"
                        :w="btnText[0]['w2']"
                        usage="router"
                        :usage-function="gotoBlogScreen"
                    ></Button>

                </v-row>
            </v-col>
        </v-container>

    </v-row>
</template>

<script>
import * as db_local from "./../../constants/home_database"
import HomeClients_ScrollBar from '@/components/scroll bars/HomeClients_ScrollBar'
import BlogEntry_Card from '@/components/cards/BlogEntry_Card'
import Button from '@/components/buttons/Button.vue'
import firebase from "firebase";
import {db} from "@/plugins/firebaseConfig";

export default {
    name: "HomeScreen",
    components: {
        HomeClients_ScrollBar,
        BlogEntry_Card,
        Button,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            blogList: [],
            imgList: [],
            imgRefList: [],
            imgLinkList: [],
            headerText: null,
            bodyText: null,
            btnText: null,
        }
    },
    computed: {
        //Gets current language
        language() {
            return this.$store.state.language
        }
    },
    beforeMount() {
        //import home database
        if (this.language === 'es') {
            this.headerText = db_local.home_header_es;
            this.bodyText = db_local.home_info_es;
            this.btnText = db_local.home_btn;
        } else {
            this.headerText = db_local.home_header_en;
            this.bodyText = db_local.home_info_en;
            this.btnText = db_local.home_btn_en;
        }
    },
    async mounted() {
        //THIS HELPS THE SIZE OF THE BUTTONS TO BE RESPONSIVE
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth
        });

        await firebase.firestore().collection('fl_content').where('_fl_meta_.status', '==', 'publish').orderBy('publishDate', 'desc').get().then((snapshot) => {
            let entriesList = snapshot.docs;
            entriesList.forEach((entry) => {
                this.blogList.push(entry.data())
            });
        });

        await this.getImageDocReference()
    },
    methods: {
        gotoBlogScreen: function () {
            this.$router.push('/blog');
        },

        gotoServiceScreen: function (index) {
            this.$router.push('/services?service=' + index);
        },

        async getImageDocReference() {
            let imageTempList = [];

            for (let i = 0; i < this.blogList.length; i++) {
                this.imgRefList.push(this.blogList[i]['coverImg'])
            }

            for (let i = 0; i < this.blogList.length; i++) {
                //Formato para el path según firebase
                this.imgRefList[i][0].path
                //Formato para el nombre del documento de la otra colección
                // this.imgRefList[i][0]._delegate._key.path.segments[6];
                let docRef = db.collection("fl_files").doc(this.imgRefList[i][0]._delegate._key.path.segments[6])
                await docRef.get().then((doc) => {
                    if (doc.exists) {
                        this.imgList.push(doc.data())
                    }
                })
            }

            for (let i = 0; i < this.blogList.length; i++) {
                let storage = firebase.storage()
                let storageRef = storage.ref()
                let imgRef = storageRef.child('flamelink/media' + '/' + this.imgList[i]['file'])
                await imgRef.getDownloadURL().then(function (url) {
                    imageTempList.push(url)
                })
            }

            this.imgLinkList.push(imageTempList)
        }
    }
}
</script>

<style scoped>
#mainText {
    margin-left: 12%;
    margin-right: 12%;
}

#slogan {
    color: #0131B7;
}

.homeScreenText {
    font-family: Montserrat, sans-serif;
    font-size: 1.2em;
    margin-right: 0;
    margin-left: 0;
    color: gray;
}

.subtitleText {
    color: #3c3c3c;
}

#blogTitle {
    color: #3c3c3c;
    margin-left: 6%
}

#BlogSection {
    margin-top: 5%;
}

.jumbotron {
    height: 92vh;
    width: 100%;
}

@media (max-width: 768px) {
    #slogan {
        color: #0131B7;
    }

    .homeScreenText {
        font-family: Montserrat, sans-serif;
        font-size: 1em;
        margin-right: 0;
        margin-left: 0;
        color: gray;
    }

    .subtitleText {
        color: #3c3c3c;
    }

    #mainText {
        margin-left: 10%;
        margin-right: 10%;
    }

    #BlogSection {
        margin-top: 5%;
        margin-left: 32%;
        margin-right: 32%;
    }
}

@media (width: 1440px) {
    #blogTitle {
        color: #3c3c3c;
        margin-left: 2.5%
    }
}
</style>
