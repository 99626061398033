<template>
  <!--Button template used globally-->
  <div class="circle_parent" @click="buttonFunction" :style="{ backgroundColor: color, width: w, height: w}">
    <div class="circle_child">
      <button class="circle_text" style="font-size: 18px" @click="buttonFunction" v-if="w === '250px'">{{name}}</button>
      <button class="circle_text" style="font-size: 14px" @click="buttonFunction" v-else>{{name}}</button>
    </div>
  </div>
</template>

<script>
export default{
  name: "Button",
  props: {
    usage: String,
    name: String,
    color: String,
    w: String,
    index: Number,
    usageFunction: Function,
  },
  methods: {
    buttonFunction(){
      if(this.usage === 'services'){
        this.usageFunction(this.index)
      }
      else{
        this.usageFunction()
      }
    },
  },
}
</script>

<style scoped>
.circle_parent {
  border-radius: 50%;
  position: relative;
}

.circle_child {
  width: 80%; /* 80% del valor del circulo padre */
  height: 80%;
  top: 50%;
  left: 50%;
  margin: -40% 0 0 -40%; /* margen negativo de la mitad de lo que mide el child */
  border-radius: 50%;
  box-shadow: inset 26px 1px 50px -4px rgba(0,0,0,0.24);
  position: absolute;
}

.circle_text{
  width: 80%;
  height: 50%;
  margin: -25% 0 0 -37%;
  top: 50%;
  left: 50%;
  text-align: center;
  position: absolute;
  color: white;
  font-size: 18px;
  font-family: "Montserrat",sans-serif;
  font-weight: 700;
}

.circle_parent:hover{
  box-shadow:  26px 1px 50px -4px rgba(0,0,0,0.50);
}
</style>
