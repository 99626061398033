<template>
    <!--Tarjeta individual de entrada de blog-->
    <v-card elevation="0" :color="color" class="cardHover px-4">
        <!--Cover image de la entrada-->
        <v-img
            @click="goToBlogEntryScreen(id)"
            height="350"
            width="350"
            :src="(img)"
        >
            <!--Botón rosa-->
            <v-btn
                color="#F4F6FD"
                elevation="0"
                class="ideenButton--text"
                text
                style="top: 85%; left: 38%"
            >
                <v-img width="15px" :src="require(`../../assets/blog/ideen_Arrow.png`)"></v-img>
            </v-btn>
        </v-img>

        <!--Título-->
        <v-card-title
            class="px-0"
            @click="goToBlogEntryScreen(id)"
            style="font-family: Montserrat, sans-serif; font-size:120%; font-weight: 600; text-align: start">{{ title }}
        </v-card-title>

        <!--Texto de la tarjeta-->
        <v-card-title
            class="px-0"
            @click="goToBlogEntryScreen(id)"
            align="start"
            style="margin-top: -2rem; font-family: Montserrat, sans-serif; font-size:100%; font-weight: normal"
        >
            {{ category.toString().toUpperCase() }}
        </v-card-title>

        <v-card-title
            class="px-0"
            @click="goToBlogEntryScreen(id)" align="start"
            style=" margin-top: -2rem; font-family: Montserrat, sans-serif; font-size:100%; font-weight: normal"
        >
            {{ date }}
        </v-card-title>

    </v-card>
</template>

<script>
export default {
    props: {
        id: String,
        title: String,
        category: String,
        date: String,
        img: String,
        color: String,
        select: String
    },

    methods: {
        //Send generated id from "BlogRelatedEntry_List" to "Blog_Entry" in order to display selected content
        goToBlogEntryScreen(entryId) {
            const routeObject = {path: "blog/" + entryId, params: {id: entryId}};

            this.$router.push(routeObject);
        },
    }
}
</script>
<style scoped>
.cardHover:hover {
    color: #0131B7;
}

</style>