<template>
    <!--Infinite slide bar showing client logos-->
    <infinite-slide-bar
        :barStyle="{ background: '#647CE0', padding: '5px 10px' }"
        direction="normal"
        duration="28s"
    >
        <div class="items">
            <div v-for="client in this.clients" :key="client.image">
                <div class="f-left pd-t-8px mg-r-10px">
                    <v-card elevation="0" height="210" width="220" color="havelockBlue">
                        <v-row class="fill-height" align="center" justify="center">
                            <v-col class="mx-16 mt-10 mb-10">
                                <v-img
                                    contain
                                    :src="require('../../assets/home/'+client.image)"
                                    height="100"
                                    max-width="180"
                                    min-width="160"
                                    class="px-8"
                                ></v-img>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
            </div>
        </div>
    </infinite-slide-bar>
</template>

<script>
//InfiniteSlideBar is an external component developed by: https://github.com/biigpongsatorn
import InfiniteSlideBar from 'vue-infinite-slide-bar'
import * as db from "./../../constants/home_database"

export default {
    name: "HomeClients_ScrollBar",
    components: {
        InfiniteSlideBar
    },
    beforeMount() {
        //Import client (home) database
        this.clients = db.client_data;
    },
}
</script>

<style scoped>
.items {
    display: flex;
    justify-content: space-around;
}
</style>